import { click, clickCustom } from './tracking';

/**
 * Bind the click event on the given element
 *
 * @param {HTMLElement|Element} element Element to bind click event to
 */
export default function bindClickEvent(element) {
  // get tracking key and other infos
  const trackingKey = element.getAttribute('data-tracking');
  const trackingData = JSON.parse(element.getAttribute('data-trackdata'));
  let href = element.getAttribute(element.nodeName === 'A' ? 'href' : 'data-href');
  const callback = (response) => {
    // redirect after the click
    if (element.nodeName === 'A') {
      if (response.length) {
        href = response;
      }

      window.location.href = href;
    }
  };
  if (trackingKey == 'custom') {
    // send click custom track
    clickCustom(trackingKey, trackingData).then((response) => callback(response));
  } else {
    // send click tracking
    click(trackingKey, trackingData).then((response) => callback(response));
  }
}
